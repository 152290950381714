@font-face {
  font-family: 'Big';
  src: url('./font/big.ttf');
}

@font-face {
  font-family: 'Small';
  src: url('./font/small.otf');
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Small';
  @media (max-width: 500px) {
    font-size: 13px;
  }
}
header {
  padding: 1em;
  padding-top: 2em;
  width: 100%;
  text-align: center;
  .logo {
    max-width: 400px;
    @media (max-width: 500px) {
      width: 80%;
    }
  }
}
main {
  padding: 1em;
}
footer {
  text-align: center;
  padding: 1em;
}

button {
  border: solid 1px #ddd;
  padding: 0em 1em;
  line-height: 1.5;
  cursor: pointer;
  background: none;
  border-radius: 0.2em;
  transition: all 0.2s ease-in;
  svg {
    vertical-align: middle !important;
  }
  &:hover,
  &:active {
    background: #eee;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #ddd;
  }
}

a {
  color: #147c88;
}

.loader {
  text-align: center;
  margin: 0 auto;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#bestiary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img.letter {
    width: 30%;
  }
  .buttons {
    button {
      margin: 0 0.5em;
    }
  }
  .section {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    button {
      margin: 1em;
    }
    .slick-list {
      width: 400px;
      .content-parent {
        vertical-align: bottom;
        .content {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 330px;
          }
          .name {
            font-family: 'Big';
            font-size: 1.7em;
            writing-mode: vertical-rl;
            text-orientation: upright;
            text-transform: uppercase;
            padding-top: 2px;
          }
        }
      }
    }
    &:nth-child(1) {
      align-items: flex-end;
      .slick-list {
        .content-parent {
          .content {
            align-items: flex-end;
          }
        }
      }
    }
    &:nth-of-type(3) {
      align-items: flex-start;
      .slick-list {
        .content-parent {
          .content {
            align-items: flex-start;
          }
        }
      }
    }
  }
  .descriptions {
    text-align: center;
    margin-top: 1em;
    .slick-list {
      width: 450px;
      .description {
        display: inline-block;
        width: 80%;
        padding: 0.5em;
        margin: 0.25em 0;
        border-radius: 0.2em;
        background: #eee;
      }
    }
    $colors: (
      #b74337,
      #c06441,
      #d6a16f,
      #d6a16f,
      #63998e,
      #147c88,
      #0a6181,
      #001f51
    );
    @each $color in $colors {
      $i: index($colors, $color);
      .description[data-i='#{$i}'] a {
        color: $color;
      }
    }
  }
  @media (max-width: 500px) {
    .section {
      button {
        display: none;
      }
      .slick-list {
        width: 350px;
        .content-parent {
          vertical-align: middle;
          .content {
            img {
              width: 248px;
            }
            .name {
              font-size: 1.5em;
            }
          }
        }
      }
    }
    .descriptions {
      .slick-list {
        width: 90vw;
        .description {
          width: 100%;
        }
      }
    }
  }
}
